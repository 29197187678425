@media (width <= 160em) {
  body {
    font-size: 1.8rem;
  }
}

@media (width <= 68em) {
  body {
    font-size: 1.6rem;
  }

  .section-nav {
    grid-template-columns: 1fr 1fr;
    height: 7rem;
  }

  .container-logo:link, .container-logo:visited, .container-logo:hover, .container-logo:active {
    height: 7rem;
    font-size: medium;
  }

  .list-nav {
    font-size: medium;
  }

  .list-el--footer {
    grid-template-columns: 1fr 1fr;
    height: 8rem;
  }

  .form {
    gap: 2.4rem;
    width: 90%;
    padding: 2.4rem 0;
  }

  .form div {
    width: 90%;
  }

  .form--textarea {
    height: 8rem;
  }

  .form--quote {
    width: 90% !important;
  }
}

@media (width <= 37.5em) {
  .section-nav {
    height: 6rem;
  }

  .container-logo:link, .container-logo:visited, .container-logo:hover, .container-logo:active {
    height: 6rem;
    font-size: smaller;
  }

  .list-nav {
    gap: 5%;
    font-size: small;
  }

  h2 {
    font-size: 1.8rem;
  }

  .list-el--footer {
    grid-template-columns: 1fr;
    gap: 2.4rem;
    height: fit-content;
    padding: 5%;
  }

  .submit-btn {
    border-radius: 2.4rem;
    height: 4rem;
    font-size: 1.6rem;
  }

  .form div {
    gap: .4rem;
  }

  .icon--close-form {
    font-size: 1.9rem;
  }

  .frankel--link-container {
    border-top: .01px solid #fafafa;
    padding: .8rem 0;
  }

  .frankel--link {
    font-size: 1.8rem;
  }

  .guitar--img {
    height: 6rem;
  }
}
/*# sourceMappingURL=home.bf9d88e5.css.map */
