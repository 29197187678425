/**************************/
/* BELOW 1920×1080 */
/**************************/
@media (max-width: 160em) {
  body {
    font-size: 1.8rem;
  }
}

/**************************/
/* BELOW 1536×864 */
/**************************/
@media (max-width: 96em) {
}

/**************************/
/* BELOW 1366×768 */
/**************************/
@media (max-width: 85.375em) {
}

/**************************/
/* BELOW 1280×720*/
/**************************/
@media (max-width: 80em) {
}

/**************************/
/* BELOW 763×1024*/
/**************************/
@media (max-width: 68em) {
  body {
    font-size: 1.6rem;
  }

  .section-nav {
    grid-template-columns: 1fr 1fr;
    height: 7rem;
  }

  .container-logo:link,
  .container-logo:visited,
  .container-logo:hover,
  .container-logo:active {
    height: 7rem;
    font-size: medium;
  }

  .list-nav {
    font-size: medium;
  }

  .list-el--footer {
    grid-template-columns: 1fr 1fr;
    height: 8rem;
  }

  .form {
    width: 90%;
    padding: 2.4rem 0;
    gap: 2.4rem;
  }

  .form div {
    width: 90%;
  }

  .form--textarea {
    height: 8rem;
  }

  .form--quote {
    width: 90% !important;
  }
}

/**************************/
/* BELOW 412×915*/
/**************************/
@media (max-width: 37.5em) {
  .section-nav {
    height: 6rem;
  }

  .container-logo:link,
  .container-logo:visited,
  .container-logo:hover,
  .container-logo:active {
    height: 6rem;
    font-size: smaller;
  }

  .list-nav {
    font-size: small;
    gap: 5%;
  }

  h2 {
    font-size: 1.8rem;
  }

  .list-el--footer {
    grid-template-columns: 1fr;
    height: fit-content;
    gap: 2.4rem;
    padding: 5%;
  }

  .submit-btn {
    height: 4rem;
    font-size: 1.6rem;
    border-radius: 2.4rem;
  }

  .form div {
    gap: 0.4rem;
  }

  .icon--close-form {
    font-size: 1.9rem;
  }

  .frankel--link-container {
    border-top: 0.01 solid #fafafa;
    padding: 0.8rem 0;
  }

  .frankel--link {
    font-size: 1.8rem;
  }

  .guitar--img {
    height: 6rem;
  }
}

/**************************/
/* BELOW 360×800*/
/**************************/
@media (max-width: 22.5em) {
}

/***********************************/
/* BELOW 390 width and 700 height*/
/***********************************/
@media (max-width: 24.375em), (max-height: 43.75em) {
}
